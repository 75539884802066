<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />
    <div v-else>
      <div class="pb-2">
        <h1 class="title title--h1 first-title title__separate">
          {{ heading.portfolio }}
        </h1>
      </div>

      <!-- Filter -->
      <div class="select">
        <span class="placeholder">Select category</span>
        <ul class="filter">
          <li class="filter__item">Category</li>
          <li class="filter__item active" data-filter="*">
            <a class="filter__link active" href="#filter">
              {{ text.allCategory }}
            </a>
          </li>
          <!-- Loop start -->
          <li
            v-for="category in categories"
            :key="category.id"
            class="filter__item"
            :data-filter="'.category-' + category.category_name"
          >
            <a class="filter__link" href="#filter">
              {{ category.category_name }}
            </a>
          </li>
          <!-- loop end -->
        </ul>
        <input type="hidden" name="changemetoo" />
      </div>
      <!-- Filter -->

      <!-- Gallery -->
      <div
        v-if="isLoading"
        class="gallery-grid-two js-grid js-filter-container"
      >
        <div class="gutter-sizer"></div>
        <figure v-for="n in 4" :key="n" class="gallery-grid__item">
          <div class="box-gallery-grid__item box__border">
            <content-loader
              :height="300"
              :speed="1"
              primaryColor="#1D1E24"
              secondaryColor="#23262B"
            >
              <rect x="0" y="0" rx="20" ry="20" width="100%" height="250" />
            </content-loader>
          </div>
        </figure>
      </div>
      <div v-else class="gallery-grid-two js-grid js-filter-container">
        <div class="gutter-sizer"></div>

        <figure
          v-for="portfolio in portfolios"
          :key="portfolio.id"
          class="gallery-grid__item"
          :class="'category-' + portfolio.portfolio_category.category_name"
        >
          <div class="box-gallery-grid__item box__border">
            <div class="gallery-grid__image-wrap">
              <img
                class="gallery-grid__image cover lazyload"
                :src="getImageUrl(portfolio.media, 'medium')"
                :alt="getImageAlt(portfolio.media)"
                data-zoom
              />
              <div
                v-if="portfolio.desc_header || portfolio.desc_body"
                class="caption"
              >
                <div class="blur"></div>
                <div class="caption-text">
                  <h1 v-if="portfolio.desc_header">
                    {{ portfolio.desc_header }}
                  </h1>
                  <p v-if="portfolio.desc_body">{{ portfolio.desc_body }}</p>
                </div>
              </div>
            </div>
            <figcaption class="gallery-grid__caption">
              <h4 class="title title--h4 gallery-grid__title">
                {{ portfolio.title }}
              </h4>
              <a
                v-for="link in portfolio.links"
                :key="link.id"
                class="link-btn"
                href="#"
                @click.prevent="openUrl(link.url)"
              >
                {{ link.title }}
              </a>
              <span class="gallery-grid__category">
                {{ portfolio.portfolio_category.category_name }}
              </span>
            </figcaption>
          </div>
        </figure>
      </div>
      <!-- Gallery -->
    </div>
  </div>
</template>

<script>
import ErrorComponent from "@/components/Error"
import { ContentLoader } from "vue-content-loader"
import { localeDetection } from "../mixins/localeDetection"
import { helper } from "../mixins/helper"

export default {
  name: "Portfolio",
  mixins: [localeDetection, helper],
  components: { ErrorComponent, ContentLoader },
  data() {
    return {
      portfolio_page: {},
      categories: [],
      portfolios: [],
      errorFetch: "",
      isLoading: true,
      heading: {
        portfolio: "Portfolio",
      },
      text: {
        allCategory: "All",
      },
    }
  },
  updated() {
    this.$loadScript("/js/jquery-3.4.1.min.js").then(() => {
      this.$loadScript("/js/plugins.min.js").then(() => {
        this.$loadScript("/js/portfolio.js")
      })
    })
  },
  created() {
    if (this.getLocale() == "id") {
      this.heading.portfolio = "Portofolio"
      this.text.allCategory = "Semua"
    }
    this.getPortfolioPage()
    this.getPortfolios()
    setTimeout(() => {
      this.getCategories()
    }, 750)
  },
  methods: {
    async getPortfolioPage() {
      this.axios
        .get("/portfolio-page")
        .then((response) => (this.portfolio_page = response.data))
        .catch((error) => (this.errorFetch = error))
    },
    async getCategories() {
      this.axios
        .get("/portfolio-categories")
        .then((response) => (this.categories = response.data))
        .catch((error) => (this.errorFetch = error))
    },
    async getPortfolios() {
      this.axios
        .get("/portfolios")
        .then((response) => {
          this.portfolios = this.shuffleArray(response.data)
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
    shuffleArray: function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
  },
  metaInfo() {
    return {
      title: this.portfolio_page.meta
        ? this.portfolio_page.meta.title
        : "Portfolio | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.portfolio_page.meta
            ? this.portfolio_page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.portfolio_page.meta
            ? this.portfolio_page.meta.title
            : "Portfolio | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.portfolio_page.meta
            ? this.portfolio_page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.portfolio_page.meta
            ? this.portfolio_page.meta.title
            : "Portfolio | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.portfolio_page.meta
            ? this.portfolio_page.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Portfolio.",
        },
      ],
    }
  },
}
</script>
