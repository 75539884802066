<template>
  <div>
    <content-loader
      v-if="isLoading"
      :height="300"
      :speed="1"
      primaryColor="#1D1E24"
      secondaryColor="#23262B"
    >
      <rect x="10%" y="20" rx="5" ry="5" width="80%" height="15" />
      <rect x="35%" y="45" rx="5" ry="5" width="30%" height="10" />
      <rect x="0" y="65" rx="5" ry="5" width="100%" height="164" />
      <rect x="0" y="240" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="260" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="280" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="300" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="320" rx="5" ry="5" width="100%" height="10" />
    </content-loader>
    <div v-else class="content">
      <router-link to="/blog" class="badge badge-primary sticky-button">
        <em class="font-icon icon-arrow-left"></em> {{ text.returnBtn }}
      </router-link>
      <div class="pb - 3">
        <header class="header-post">
          <h1 class="title title--h1">{{ blog.title }}</h1>
          <p>{{ monthDayYearFormat(blog.published_at) }}</p>
          <div class="caption-post">
            <p>
              {{ blog.caption }}
            </p>
          </div>
          <div class="header-post__image-wrap">
            <img
              class="cover lazyload"
              :src="getImageUrl(this.blog.image, 'medium')"
              :alt="getImageAlt(this.blog.image)"
            />
          </div>
          <p v-if="blog.series" class="box__border series__part">
            {{ text.partOfSeries }}
            <router-link :to="'/series/' + blog.series.id">
              {{ blog.series.title }}
            </router-link>
            series
          </p>
        </header>
        <div class="caption-post">
          <vue-markdown :source="blog.content" />
        </div>
        <footer class="footer-post">
          <a
            class="footer-post__share"
            href="https://www.github.com/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-github"></em> <span>Github</span>
          </a>
          <a
            class="footer-post__share"
            href="https://www.twitter.com/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-twitter"></em> <span>Twitter</span>
          </a>
          <a
            class="footer-post__share"
            href="https://www.linkedin.com/in/adityacprtm"
            target="_blank"
            rel="noopener"
          >
            <em class="font-icon icon-linkedin"></em> <span>Linkedin</span>
          </a>
        </footer>
      </div>

      <div class="box-inner box-inner--rounded">
        <h2 class="title title--h3">
          <DisqusCount
            shortname="adityacprtm"
            :identifier="pageConfig.identifier"
          />
        </h2>
        <Disqus :pageConfig="pageConfig" shortname="adityacprtm" lang="Id_ID" />
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render"
import { ContentLoader } from "vue-content-loader"
import { dateFormat } from "../mixins/dateFormat"
import { localeDetection } from "../mixins/localeDetection"
import { helper } from "../mixins/helper"
import { Disqus, DisqusCount } from "vue-disqus"

export default {
  name: "BlogDetail",
  mixins: [dateFormat, localeDetection, helper],
  components: {
    VueMarkdown,
    ContentLoader,
    Disqus,
    DisqusCount,
  },
  data() {
    return {
      blog: {},
      isLoading: true,
      pageConfig: {
        identifier: this.$route.path,
      },
      text: {
        returnBtn: "Return to Blog List",
        partOfSeries: "This blog is part of",
      },
      suffixLocaleID: "ind",
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.text.returnBtn = "Kembali ke Daftar Blog"
      this.text.partOfSeries = "Blog ini bagian dari"
    }
    this.getBlogPost()
  },
  methods: {
    async getBlogPost() {
      let slug = this.$route.params.slug

      if (
        this.getLocale() == "en" &&
        slug.split("-")[slug.split("-").length - 1] == this.suffixLocaleID
      ) {
        let arrSlug = slug.split("-")
        arrSlug.pop()
        slug = arrSlug.join("-")
      } else if (
        this.getLocale() == "id" &&
        slug.split("-")[slug.split("-").length - 1] != this.suffixLocaleID
      ) {
        slug = slug + "-" + this.suffixLocaleID
      }

      this.axios
        .get("/blogs?slug=" + slug)
        .then((response) => {
          if (response.data[0]) {
            this.blog = response.data[0]
            this.isLoading = false
          } else {
            this.$router.push({ path: "/NotFound" })
          }
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: this.blog.meta
        ? this.blog.meta.title + " | Blog | Adityacprtm"
        : "Blog | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: this.blog.meta
            ? this.blog.meta.title + " | Blog | Adityacprtm"
            : "Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:image",
          property: "twitter:image",
          content: this.getImageUrl(this.blog.image),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.blog.meta
            ? this.blog.meta.title + " | Blog | Adityacprtm"
            : "Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.getImageUrl(this.blog.image),
        },
      ],
    }
  },
}
</script>
