<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />
    <div v-else>
      <div class="pb-2">
        <h1 class="title title--h1 first-title title__separate">
          {{ this.heading.resume }}
        </h1>
      </div>

      <!-- Education -->
      <div class="row">
        <!-- Education Formal -->
        <div class="col-sm">
          <h2 class="title title--h3">
            <img
              class="title-icon"
              src="../assets/icons/icon-education.svg"
              alt="education"
            />
            {{ this.heading.education }}
          </h2>
          <div v-if="isLoading">
            <content-loader
              v-for="n in 2"
              :key="n"
              :speed="1"
              :height="120"
              primaryColor="#1D1E24"
              secondaryColor="#23262B"
            >
              <circle cx="10" cy="15" r="8" />
              <rect x="30" y="10" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="40" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="70" rx="5" ry="5" width="80%" height="10" />
            </content-loader>
          </div>
          <div v-else class="timeline">
            <article
              v-for="education in resume.educations"
              :key="education.title"
              class="timeline__item"
            >
              <h5 class="title title--h5 timeline__title">
                {{ education.institution }}
              </h5>
              <span class="timeline__period">
                {{ monthYearFormat(education.start_date) }} -
                {{
                  education.end_date
                    ? monthYearFormat(education.end_date)
                    : "Present"
                }}
              </span>
              <p class="timeline__description">
                <span v-if="education.description">
                  {{ education.description }}
                  <br />
                </span>
                {{ education.location }}
              </p>
            </article>
          </div>
        </div>
        <!-- Education Formal -->

        <!-- Education Non Formal -->
        <div :class="isMobile() ? 'col-sm mt-4' : 'col-sm'">
          <h2 class="title title--h3">
            <img
              class="title-icon"
              src="../assets/icons/icon-education.svg"
              alt="education"
            />
            {{ this.heading.education2 }}
          </h2>
          <div v-if="isLoading">
            <content-loader
              v-for="n in 2"
              :key="n"
              :speed="1"
              :height="120"
              primaryColor="#1D1E24"
              secondaryColor="#23262B"
            >
              <circle cx="10" cy="15" r="8" />
              <rect x="30" y="10" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="40" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="70" rx="5" ry="5" width="80%" height="10" />
            </content-loader>
          </div>
          <div v-else class="timeline">
            <article
              v-for="education in resume.nonformal_education"
              :key="education.title"
              class="timeline__item"
            >
              <h5 class="title title--h5 timeline__title">
                {{ education.institution }}
              </h5>
              <span class="timeline__period">
                {{ monthYearFormat(education.start_date) }} -
                {{
                  education.end_date
                    ? monthYearFormat(education.end_date)
                    : "Present"
                }}
                ( {{ education.hours }} Hours)
              </span>
              <p class="timeline__description">
                <span v-if="education.description">
                  {{ education.description }}
                  <br />
                </span>
                {{ education.location }}
              </p>
            </article>
          </div>
        </div>
        <!-- Education Non Formal -->
      </div>
      <!-- Education -->

      <!-- Experience -->
      <div class="row mt-4">
        <div class="col">
          <h2 class="title title--h3">
            <img
              class="title-icon"
              src="../assets/icons/icon-experience.svg"
              alt="experience"
            />
            {{ this.heading.experience }}
          </h2>
          <div v-if="isLoading">
            <content-loader
              v-for="n in 2"
              :key="n"
              :speed="1"
              :width="isMobile() ? 330 : 660"
              :height="120"
              primaryColor="#1D1E24"
              secondaryColor="#23262B"
            >
              <circle cx="10" cy="15" r="8" />
              <rect x="30" y="10" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="40" rx="5" ry="5" width="80%" height="10" />
              <rect x="30" y="70" rx="5" ry="5" width="80%" height="10" />
            </content-loader>
          </div>
          <div v-else class="timeline">
            <article
              v-for="experience in resume.experiences"
              :key="experience.title"
              class="timeline__item"
            >
              <h5 class="title title--h5 timeline__title">
                {{ experience.institution }}
                <small> {{ text.asExperience }} </small>
                {{ experience.position }}
              </h5>
              <span class="timeline__period">
                {{ monthYearFormat(experience.start_date) }}
                -
                {{
                  experience.end_date
                    ? monthYearFormat(experience.end_date)
                    : "Present"
                }}
              </span>
              <p class="timeline__description">
                <span v-if="experience.location">
                  {{ experience.description }}
                  <br />
                </span>
                {{ experience.location }}
              </p>
            </article>
          </div>
        </div>
      </div>
      <!-- Experience -->

      <!-- Skills -->
      <div class="row mt-4">
        <div class="col-12">
          <h2 class="title title--h3">{{ this.heading.skills }}</h2>
          <div class="box box__border">
            <div v-if="isLoading">
              <content-loader
                v-if="isLoading"
                :speed="1"
                :width="isMobile() ? 200 : 400"
                :height="110"
                primaryColor="#1D1E24"
                secondaryColor="#23262B"
              >
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="23" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="46" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="69" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="92" rx="6" ry="6" width="100%" height="12" />
              </content-loader>
            </div>

            <div v-else>
              <div
                v-for="skill in resume.skills"
                v-bind:key="skill.id"
                class="progress"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="
                    'width: ' + getSkillValue(skill.level) + '%; z-index: 2;'
                  "
                >
                  <div class="progress-text">
                    <span> {{ skill.title }} </span>
                    <span> {{ isMobile() ? "" : skill.level }} </span>
                  </div>
                </div>
                <div class="progress-text">
                  <span> {{ skill.title }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Skills -->

      <!-- Code Skills -->
      <div class="row mt-4">
        <div class="col-12">
          <h2 class="title title--h3">{{ this.heading.code_skills }}</h2>
          <div class="box box__border">
            <div v-if="isLoading">
              <content-loader
                v-if="isLoading"
                :speed="1"
                :width="isMobile() ? 200 : 400"
                :height="110"
                primaryColor="#1D1E24"
                secondaryColor="#23262B"
              >
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="23" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="46" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="69" rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y="92" rx="6" ry="6" width="100%" height="12" />
              </content-loader>
            </div>
            <div v-else>
              <div
                v-for="skill in resume.code_skills"
                v-bind:key="skill.id"
                class="progress"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="
                    'width: ' + getSkillValue(skill.level) + '%; z-index: 2;'
                  "
                >
                  <div class="progress-text">
                    <span> {{ skill.title }} </span>
                    <span> {{ isMobile() ? "" : skill.level }} </span>
                  </div>
                </div>
                <div class="progress-text">
                  <span> {{ skill.title }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CodeSkills -->
    </div>
  </div>
</template>

<script>
import ErrorComponent from "@/components/Error"
import { ContentLoader } from "vue-content-loader"
import { dateFormat } from "../mixins/dateFormat"
import { mobileDetection } from "../mixins/mobileDetection"
import { localeDetection } from "../mixins/localeDetection"

export default {
  name: "Resume",
  mixins: [dateFormat, mobileDetection, localeDetection],
  components: {
    ErrorComponent,
    ContentLoader,
  },
  data() {
    return {
      resume: {},
      isLoading: true,
      errorFetch: "",
      heading: {
        resume: "Resume",
        education: "Education",
        education2: "Nonformal Education",
        experience: "Experience",
        skills: "Skills",
        code_skills: "Code Skills",
      },
      text: {
        asExperience: "as",
      },
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.heading.resume = "Resume"
      this.heading.education = "Pendidikan"
      this.heading.education2 = "Pendidikan Nonformal"
      this.heading.experience = "Pengalaman"
      this.heading.skills = "Keterampilan"
      this.heading.code_skills = "Keterampilan Code"

      this.text.asExperience = "sebagai"
    }
    this.getResumePage()
  },
  methods: {
    async getResumePage() {
      this.axios
        .get("/resume-page")
        .then((response) => {
          this.resume = response.data
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
    getSkillValue: function (level) {
      // Beginner, Intermediate, Expert
      if (level == "Beginner") {
        return 33
      } else if (level == "Intermediate") {
        return 66
      }
      return 100
    },
  },
  metaInfo() {
    return {
      title: this.resume.meta
        ? this.resume.meta.title
        : "Resume | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.resume.meta
            ? this.resume.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.resume.meta
            ? this.resume.meta.title
            : "Resume | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.resume.meta
            ? this.resume.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.resume.meta
            ? this.resume.meta.title
            : "Resume | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.resume.meta
            ? this.resume.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Resume.",
        },
      ],
    }
  },
}
</script>
