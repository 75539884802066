<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />

    <div v-else class="content">
      <div class="section mt-0">
        <h1 class="title title--h1 title__separate">
          {{ this.heading.seriesList }}
        </h1>
      </div>

      <div v-if="isLoading" class="news-grid section">
        <article v-for="n in 2" :key="n" class="news-item box">
          <content-loader
            :speed="1"
            primaryColor="#1D1E24"
            secondaryColor="#23262B"
          >
            <rect x="0" y="0" rx="20" ry="20" width="100%" height="130" />
          </content-loader>
        </article>
      </div>

      <div v-else class="news-grid section">
        <article
          v-for="series in series"
          :key="series.id"
          class="news-item box"
        >
          <div class="news-item__caption series__caption">
            <router-link :to="'/series/' + series.id">
              <v-clamp
                autoresize
                :max-lines="2"
                tag="h3"
                class="title title--h4"
              >
                {{ series.title }}
              </v-clamp>
            </router-link>
            <v-clamp autoresize tag="p" :max-lines="2">
              {{ series.countBlogs }} {{ text.partSeries }}
            </v-clamp>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import { ContentLoader } from "vue-content-loader"
import ErrorComponent from "@/components/Error"
import { localeDetection } from "../mixins/localeDetection"

export default {
  name: "Series",
  mixins: [localeDetection],
  components: {
    VClamp,
    ContentLoader,
    ErrorComponent,
  },
  data() {
    return {
      series: {},
      isLoading: true,
      errorFetch: "",
      heading: {
        seriesList: "Series List",
      },
      text: {
        partSeries: "Part Series",
      },
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.heading.seriesList = "Daftar Series"
      this.text.partSeries = "Bagian Series"
    }
    this.getSeries()
  },
  methods: {
    async getSeries() {
      this.axios
        .get("/series?_sort=published_at:DESC")
        .then((response) => {
          this.series = response.data
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: "Blog Series | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "Blog Series | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Blog Series | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Get to know Aditya Chamim Pratama posts.",
        },
      ],
    }
  },
}
</script>
