<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />
    <div v-else>
      <div class="pb-2">
        <h1 class="title title--h1 first-title title__separate">Journey</h1>
      </div>

      <!-- Journey -->
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading">
            <content-loader
              v-for="n in 4"
              :key="n"
              :speed="1"
              :width="isMobile() ? 300 : 600"
              :height="70"
              primaryColor="#1D1E24"
              secondaryColor="#23262B"
            >
              <circle cx="8" cy="25" r="6" />
              <rect x="24" y="20" rx="5" ry="5" width="80%" height="10" />
              <rect x="24" y="40" rx="5" ry="5" width="80%" height="10" />
            </content-loader>
          </div>
          <div v-else class="timeline">
            <article
              v-for="journey in journey.lists"
              :key="journey.id"
              class="timeline__item"
            >
              <h5 class="title title--h5 timeline__title">
                {{ monthDayYearFormat(journey.date) }}
                <span style="font-weight: normal">
                  <vue-markdown :source="journey.description" />
                </span>
              </h5>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render"
import ErrorComponent from "@/components/Error"
import { dateFormat } from "../mixins/dateFormat"
import { mobileDetection } from "../mixins/mobileDetection"
import { ContentLoader } from "vue-content-loader"

export default {
  name: "Journey",
  mixins: [dateFormat, mobileDetection],
  data() {
    return {
      journey: {},
      isLoading: true,
      errorFetch: "",
    }
  },
  components: {
    VueMarkdown,
    ErrorComponent,
    ContentLoader,
  },
  created() {
    this.getJourneyPage()
  },
  methods: {
    async getJourneyPage() {
      this.axios
        .get("/journey-page")
        .then((response) => {
          this.journey = response.data
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: this.journey.meta
        ? this.journey.meta.title
        : "Journey | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.journey.meta
            ? this.journey.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.journey.meta
            ? this.journey.meta.title
            : "Journey | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.journey.meta
            ? this.journey.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.journey.meta
            ? this.journey.meta.title
            : "Journey | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.journey.meta
            ? this.journey.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Journey.",
        },
      ],
    }
  },
}
</script>
