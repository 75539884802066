<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />

    <div v-else class="content">
      <div class="section mt-0">
        <h1 class="title title--h1 title__separate">{{ heading.blog }}</h1>
      </div>

      <div class="news-grid section">
        <article v-for="blog in blogs" :key="blog.id" class="news-item box">
          <div class="news-item__image-wrap overlay overlay--45">
            <div class="news-item__date">
              {{ dayFormat(blog.published_at) }}
              <span>
                {{ monthFormat(blog.published_at) }}
              </span>
            </div>
            <router-link :to="'/blog/' + blog.slug" class="news-item__link" />
            <img
              class="cover lazyload"
              :src="getImageUrl(blog.image, 'small')"
              :alt="getImageAlt(blog.image)"
            />
          </div>
          <div class="news-item__caption">
            <h3 class="title title--h4">
              <v-clamp autoresize :max-lines="2">
                {{ blog.title }}
              </v-clamp>
            </h3>
            <v-clamp autoresize tag="p" :max-lines="2">
              {{ blog.caption }}
            </v-clamp>
          </div>
        </article>
      </div>
      <infinite-loading @infinite="getBlogs">
        <div slot="no-more">{{ text.endBlog }}</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import ErrorComponent from "@/components/Error"
import { dateFormat } from "../mixins/dateFormat"
import InfiniteLoading from "vue-infinite-loading"
import { localeDetection } from "../mixins/localeDetection"
import { helper } from "../mixins/helper"

export default {
  name: "Blog",
  mixins: [dateFormat, localeDetection, helper],
  components: {
    VClamp,
    ErrorComponent,
    InfiniteLoading,
  },
  data() {
    return {
      blogs: [],
      blog: {},
      errorFetch: "",
      fetchCounter: 0,
      fetchLimit: 4,
      heading: {
        blog: "Blog",
      },
      text: {
        endBlog: "You're at the end :)",
      },
    }
  },
  created() {
    // this.getBlogs()
    if (this.getLocale() == "id") {
      this.heading.blog = "Blog"
      this.text.endBlog = "Anda berada di akhir :)"
    }
    this.getBlogPage()
  },
  methods: {
    async getBlogPage() {
      this.axios
        .get("/blog-page")
        .then((response) => (this.blog = response.data))
        .catch((error) => (this.errorFetch = error))
    },
    async getBlogs($state) {
      this.axios
        .get(
          "/blogs?_sort=published_at:DESC&_start=" +
            this.fetchCounter +
            "&_limit=" +
            this.fetchLimit
        )
        .then((response) => {
          if (response.data.length) {
            response.data.forEach((object) => {
              this.blogs.push(object)
            })
            // this.blogs = response.data
            this.fetchCounter += this.fetchLimit
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: this.blog.meta
        ? this.blog.meta.title
        : "Blog | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.blog.meta
            ? this.blog.meta.title
            : "Blog | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.blog.meta
            ? this.blog.meta.title
            : "Blog | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.blog.meta
            ? this.blog.meta.description
            : "Get to know Aditya Chamim Pratama posts.",
        },
      ],
    }
  },
}
</script>
