<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />

    <div v-else class="content">
      <div class="section mt-0">
        <h1 class="title title--h1 title__separate">Blog Series</h1>
        <router-link to="/series" class="badge badge-primary mb-4">
          <em class="font-icon icon-arrow-left"></em> {{ text.returnBtn }}
        </router-link>
        <h3 class="title title--h3 text-center">{{ series.title }}</h3>
        <br />
      </div>

      <div v-if="isLoading" class="news-grid section">
        <article v-for="n in 4" :key="n" class="news-item box">
          <content-loader
            :height="300"
            :speed="1"
            primaryColor="#1D1E24"
            secondaryColor="#23262B"
          >
            <rect x="0" y="0" rx="20" ry="20" width="100%" height="300" />
          </content-loader>
        </article>
      </div>
      <div v-else class="news-grid section">
        <div v-if="!series.blogs.length" style="margin: auto">
          <p>Oops! This series is coming soon!</p>
        </div>
        <article
          v-for="(blog, index) in series.blogs"
          :key="blog.id"
          class="news-item box"
        >
          <div class="news-item__image-wrap overlay overlay--45">
            <div class="news-item__date">
              {{ dayFormat(blog.published_at) }}
              <span>
                {{ monthFormat(blog.published_at) }}
              </span>
            </div>
            <div class="news-item__part">
              <span> {{ text.part }} </span>
              {{ index + 1 }}
            </div>
            <router-link :to="'/blog/' + blog.slug" class="news-item__link" />
            <img
              class="cover lazyload"
              :src="
                blog.image
                  ? blog.image.formats.small
                    ? blog.image.formats.small.url
                    : blog.image.url
                  : '/static/social.jpg'
              "
              :alt="
                blog.image
                  ? blog.image.alternativeText
                    ? blog.image.alternativeText
                    : blog.title
                  : 'Adityacprtm'
              "
            />
          </div>
          <div class="news-item__caption">
            <h3 class="title title--h4">
              <v-clamp autoresize :max-lines="2">
                {{ blog.title }}
              </v-clamp>
            </h3>
            <v-clamp autoresize tag="p" :max-lines="2">
              {{ blog.caption }}
            </v-clamp>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp"
import { ContentLoader } from "vue-content-loader"
import ErrorComponent from "@/components/Error"
import { dateFormat } from "../mixins/dateFormat"
import { localeDetection } from "../mixins/localeDetection"

export default {
  name: "SeriesList",
  mixins: [dateFormat, localeDetection],
  components: {
    VClamp,
    ContentLoader,
    ErrorComponent,
  },
  data() {
    return {
      series: {},
      isLoading: true,
      errorFetch: "",
      text: {
        returnBtn: "Return to Series List",
        part: "Part",
      },
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.text.returnBtn = "Kembali ke Daftar Series"
      this.text.part = ""
    }
    this.getBlogSeries()
  },
  methods: {
    async getBlogSeries() {
      this.axios
        .get("/series/" + this.$route.params.id)
        .then((response) => {
          this.series = response.data
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: this.series.title
        ? this.series.title
        : "Blog Series | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.series.title
            ? this.series.title
            : "Blog Series | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.series.title
            ? this.series.title
            : "Blog | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.series.title
            ? this.series.title
            : "Blog Series by Adityacprtm.",
        },
      ],
    }
  },
}
</script>
