<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />
    <div v-else>
      <div class="pb-2">
        <h1 class="title title--h1 first-title title__separate">
          {{ this.heading.contact }}
        </h1>
        <p>
          {{ this.opportunity }}
          <strong>{{ contact.opportunities ? "Yes" : "No" }}</strong>
        </p>
      </div>

      <!-- Contact -->
      <h2 class="title title--h3">{{ this.heading.contact_form }}</h2>
      <div v-if="isBot">
        <p class="with-errors">
          Great! You have proven to be a bot! I've destroyed the form from the
          page, and acted like you successfully sent the form.
        </p>
      </div>
      <form
        v-else
        id="contact-form"
        name="contact-form"
        class="contact-form"
        @submit="onSubmit"
      >
        <div class="row">
          <div
            class="form-group col-12 col-md-6"
            :class="{ 'has-error': errorName, 'has-danger': errorName }"
          >
            <input
              v-model="name"
              type="text"
              class="form-control"
              id="name"
              name="name"
              :placeholder="placeholder.fullname"
            />
            <div v-if="errorName" class="help-block with-errors">
              {{ errorName }}
            </div>
          </div>
          <div class="hidden form-group col-12 col-md-6">
            <input
              v-model="bot"
              type="text"
              class="form-control"
              id="bot-field"
              name="bot-field"
              placeholder="Don’t fill this out if you're human"
            />
          </div>
          <div
            class="form-group col-12 col-md-6"
            :class="{ 'has-error': errorEmail, 'has-danger': errorEmail }"
          >
            <input
              v-model="email"
              type="email"
              class="form-control"
              id="email"
              name="email"
              :placeholder="placeholder.email"
            />
            <div v-if="errorEmail" class="help-block with-errors">
              {{ errorEmail }}
            </div>
          </div>
          <div
            class="form-group col-12 col-md-12"
            :class="{ 'has-error': errorMessage, 'has-danger': errorMessage }"
          >
            <textarea
              v-model="message"
              class="textarea form-control"
              id="message"
              name="message"
              :placeholder="placeholder.message"
              rows="4"
            >
            </textarea>
            <div v-if="errorMessage" class="help-block with-errors">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 order-2 order-md-1 text-center text-md-left"
          >
            <p v-if="successForm" class="with-success">
              {{ messageForm.success }}
            </p>
            <p v-if="errorForm" class="with-errors">
              {{ messageForm.error }}
            </p>
            <p v-if="loading">
              {{ messageForm.loading }}
            </p>
          </div>
          <div class="col-12 col-md-6 order-1 order-md-2 text-right">
            <button type="submit" class="btn" :class="{ disabled: loading }">
              <em class="font-icon icon-send"></em>
              {{ loading ? placeholder.sending : placeholder.submit }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ErrorComponent from "@/components/Error"
import { localeDetection } from "../mixins/localeDetection"

export default {
  name: "Contact",
  mixins: [localeDetection],
  components: { ErrorComponent },
  data() {
    return {
      contact: {},
      loading: false,
      isBot: false,
      bot: null,
      name: null,
      email: null,
      message: null,
      errorName: null,
      errorEmail: null,
      errorMessage: null,
      successForm: false,
      errorForm: false,
      errorFetch: "",
      heading: {
        contact: "Contact",
        contact_form: "Contact Form",
      },
      opportunity: "Open for opportunities:",
      placeholder: {
        fullname: "Full name",
        email: "Email address",
        message: "Your message",
        sending: "Sending Message...",
        submit: "Submit",
      },
      messageForm: {
        success: "Thanks! Your message has been sent.",
        error: "Oopss! Something went wrong.",
        loading:
          "If this takes forever, I am so sorry in advance. My backend is hosted on Heroku for free.😉",
      },
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.heading.contact = "Kontak"
      this.heading.contact_form = "Formulir Kontak"

      this.opportunity = "Terbuka untuk peluang:"

      this.placeholder.fullname = "Nama lengkap"
      this.placeholder.email = "Alamat email"
      this.placeholder.message = "Pesan anda"
      this.placeholder.sending = "Mengirim pesan..."
      this.placeholder.submit = "Kirim"

      this.messageForm.success = "Terima kasih! Pesan Anda telah dikirim."
      this.messageForm.error = "Ups! Ada sesuatu yang salah."
      this.messageForm.loading =
        "Jika ini memakan waktu lama, saya mohon maaf sebelumnya. Backend saya dihosting di Heroku secara gratis.😉"
    }
    this.getContactPage()
  },
  methods: {
    async getContactPage() {
      this.axios
        .get("/contact-page")
        .then((response) => (this.contact = response.data))
        .catch((error) => (this.errorFetch = error))
    },
    onSubmit(e) {
      if (!this.name) {
        this.errorName = "Please fill your name ..."
        setTimeout(() => {
          this.errorName = null
        }, 5000)
      }

      if (this.bot != null) {
        this.isBot = true
      }

      if (!this.email) {
        this.errorEmail = "Please fill your email ..."
        setTimeout(() => {
          this.errorEmail = null
        }, 5000)
      } else if (!this.validateEmail(this.email)) {
        this.errorEmail = "Please fill your email correctly ..."
        setTimeout(() => {
          this.errorEmail = null
        }, 5000)
      }

      if (!this.message) {
        this.errorMessage = "Please fill in the form..."
        setTimeout(() => {
          this.errorMessage = null
        }, 5000)
      }

      if (!this.errorName && !this.errorEmail && !this.errorMessage) {
        this.submitMessage()
      }

      e.preventDefault()
    },
    submitMessage() {
      this.successForm = false
      this.errorForm = false
      this.loading = true
      if (this.bot != null) {
        this.isBot = true
      } else {
        this.axios
          .post("/messages", {
            name: this.name,
            email: this.email,
            message: this.message,
          })
          .then(() => {
            this.successForm = true
            this.errorForm = false
            this.name = null
            this.email = null
            this.message = null
            setTimeout(() => {
              this.successForm = false
            }, 5000)
          })
          .catch(() => {
            this.successForm = false
            this.errorForm = true
            setTimeout(() => {
              this.errorForm = false
            }, 5000)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
  metaInfo() {
    return {
      title: this.contact.meta
        ? this.contact.meta.title
        : "Contact | Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.contact.meta
            ? this.contact.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Contact.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.contact.meta
            ? this.contact.meta.title
            : "Contact | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.contact.meta
            ? this.contact.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Contact.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.contact.meta
            ? this.contact.meta.title
            : "Contact | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.contact.meta
            ? this.contact.meta.description
            : "Get to know Aditya Chamim Pratama more deeply with Contact.",
        },
      ],
    }
  },
}
</script>
