<template>
  <div>
    <ErrorComponent
      v-if="errorFetch"
      title="Error while fetching data"
      :msg="errorFetch"
    />
    <div v-else>
      <!-- About -->
      <div class="pb-0 pb-sm-2">
        <h1 class="title title--h1 first-title title__separate">
          {{ this.heading.aboutme }}
        </h1>
        <content-loader
          v-if="isLoading"
          :speed="1"
          :width="isMobile() ? 200 : 400"
          :height="90"
          primaryColor="#1D1E24"
          secondaryColor="#23262B"
        >
          <rect x="0" y="5" rx="4" ry="4" width="100%" height="8" />
          <rect x="0" y="20" rx="4" ry="4" width="100%" height="8" />
          <rect x="0" y="35" rx="4" ry="4" width="100%" height="8" />
          <rect x="0" y="50" rx="4" ry="4" width="100%" height="8" />
          <rect x="0" y="65" rx="4" ry="4" width="100%" height="8" />
          <rect x="0" y="80" rx="4" ry="4" width="100%" height="8" />
        </content-loader>
        <span v-else style="white-space: pre-wrap">{{ this.home.about }}</span>
      </div>
      <!-- About -->

      <!-- Scopes -->
      <div class="mt-4">
        <h2 class="title title--h3">{{ this.heading.scope }}</h2>

        <div v-if="isLoading" class="row">
          <div v-for="n in 4" :key="n" class="col-12 col-lg-6">
            <div class="case-item">
              <content-loader
                :height="200"
                :speed="1"
                primaryColor="#1D1E24"
                secondaryColor="#23262B"
              >
                <rect x="0" y="0" rx="20" ry="20" width="100%" height="200" />
              </content-loader>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div
            v-for="scope in home.scopes"
            :key="scope.id"
            class="col-12 col-lg-6"
          >
            <div class="case-item">
              <img
                v-if="scope.icon"
                :src="scope.icon.url"
                :alt="scope.icon.alternativeText"
                width="40"
                height="40"
              />
              <div class="mt-3">
                <h3 class="title title--h4">{{ scope.title }}</h3>
                <p class="case-item__caption">
                  {{ scope.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Scopes -->

      <!-- Clients -->
      <div class="mt-4">
        <h2 class="title title--h3">{{ this.heading.client }}</h2>

        <div v-if="isLoading" class="swiper-container js-carousel-clients">
          <div class="swiper-wrapper">
            <div v-for="n in 3" :key="n">
              <content-loader
                :speed="1"
                primaryColor="#1D1E24"
                secondaryColor="#23262B"
              >
                <rect x="0" y="0" rx="20" ry="20" width="80%" height="80" />
              </content-loader>
            </div>
          </div>
        </div>

        <div v-else class="swiper-container js-carousel-clients">
          <div class="swiper-wrapper">
            <div
              v-for="client in home.clients"
              :key="client.id"
              class="swiper-slide"
            >
              <a
                href="#"
                @click.prevent="openUrl(client.url)"
                :title="client.title"
              >
                <img
                  v-if="client.image"
                  width="200"
                  height="93"
                  :src="getImageUrl(client.image)"
                  :alt="getImageAlt(client.image)"
                />
              </a>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- Clients -->
    </div>
  </div>
</template>

<script>
import ErrorComponent from "@/components/Error"
import { ContentLoader } from "vue-content-loader"
import { mobileDetection } from "../mixins/mobileDetection"
import { localeDetection } from "../mixins/localeDetection"
import { helper } from "../mixins/helper"

export default {
  name: "About",
  mixins: [mobileDetection, localeDetection, helper],
  components: {
    ErrorComponent,
    ContentLoader,
  },
  data() {
    return {
      home: {},
      isLoading: true,
      errorFetch: "",
      heading: {
        aboutme: "About Me",
        scope: "What I'm Doing",
        client: "Clients",
      },
    }
  },
  created() {
    if (this.getLocale() == "id") {
      this.heading.aboutme = "Tentang Saya"
      this.heading.scope = "Apa yang Saya Kerjakan"
      this.heading.client = "Klien"
    }
    this.getHomePage()
  },
  methods: {
    async getHomePage() {
      this.axios
        .get("/home-page")
        .then((response) => {
          this.home = response.data
          this.isLoading = false
        })
        .catch((error) => (this.errorFetch = error))
    },
  },
  metaInfo() {
    return {
      title: this.home.meta
        ? this.home.meta.title
        : "Adityacprtm | DevOps & SRE",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.home.meta
            ? this.home.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.home.meta
            ? this.home.meta.title
            : "Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.home.meta
            ? this.home.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.home.meta
            ? this.home.meta.title
            : "Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.home.meta
            ? this.home.meta.description
            : "Get to know Aditya Chamim Pratama.",
        },
      ],
    }
  },
}
</script>
